.case-studies h3,
.case-study h3 {
    background: none;
    font-weight: bold;
    color: #0a577b;
}

.case-studies .step-title {
    display: block;
}

.work-button {
    display: inline-block;
    margin-top: 10px;
    background: #0a577b;
    color: white;
    padding: 10px 20px;
    border-radius: calc(1em + 10px);
    text-align: right;
}