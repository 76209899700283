* {
	box-sizing: border-box;
}

html,
body {
	font-size: 10px;
	background: #fff;
	color: black;
	font-weight: 400;
}

body {
	margin: 0;
	font-family: freight-sans-pro;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
