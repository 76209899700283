.step-container.about-1-container {
    padding-top: 0;
}

.step-title.about-1 {
	background: url("./about_1.png");
	background-size: cover;
	background-position: center center;
}

.step-title.about-2 {
	background: url("./about_2.png");
	background-size: cover;
	background-position: center center;
}

.step-title.about-3 {
	background: url("./about_3.png");
	background-size: cover;
	background-position: center center;
}

.step-title.about-4 {
	background: url("./about_4.png");
	background-size: cover;
	background-position: center center;
}