.header-container,
.article-header-container {
	border-bottom: 1px solid #09415b;

	height: 150px;
	width: 100%;

	position: relative;

	color: #09415b;
	overflow: hidden;
}

.article-header-container {
	height: auto;
}

.page-header {
	/* background: url("./slide-blue.png"); */
	background: #DDDDDD55;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;

	height: 100%;
	width: 100%;

	color: #09415b;

	position: relative;
	z-index: 1;
}

.header-container:before,
.header-container:after {
	content: "";
	border-radius: 50%;
	position: absolute;
	z-index: 0;
	opacity: 0.35;
}

.header-container:before {
	background: #1281b3;
	width: 800px;
	height: 800px;
	top: -490px;
	left: -310px;
}

.header-container:after {
	background: #fa0;
	width: 250px;
	height: 250px;
	bottom: -150px;
	right: 10px;
	opacity: 0.75;
}

.page-header > h1 {
	margin: 0;
}

@media (max-width: 799px) {
	.page-header > h1 {
		font-size: 3.7rem;
    	padding: 0 10px;
	}
}
