.step-title.step-one {
	background: url("./services_1.png");
	background-size: cover;
	background-position: center center;
}
.step-title.step-two {
	background: url("./services_2.png");
	background-size: cover;
	background-position: center center;
}
.step-title.step-three {
	background: url("./services_3.png");
	background-size: cover;
	background-position: center center;
}
.step-title.step-four {
	background: url("./services_4.png");
	background-size: cover;
	background-position: center center;
}
.step-title.step-five {
	background: url("./services_5.png");
	background-size: cover;
	background-position: center center;
}
.step-title.step-six {
	background: url("./services_6.png");
	background-size: cover;
	background-position: center center;
}
.step-title.step-seven {
	background: url("./services_7.png");
	background-size: cover;
	background-position: center center;
}
.step-title.step-eight {
	background: url("./services_8.png");
	background-size: cover;
	background-position: center center;
}
