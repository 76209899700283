.step-title.step-review {
	background: url("./review.png");
	background-size: cover;
	background-position: center center;
}

.step-title.step-strategize {
	background: url("./strategize.png");
	background-size: cover;
	background-position: center center;
}

.step-title.step-develop {
	background: url("./develop.png");
	background-size: cover;
	background-position: center center;
}

.step-title.step-integrate {
	background: url("./integrate.png");
	background-size: cover;
	background-position: center center;
}

.step-title.step-automate {
	background: url("./automate.png");
	background-size: cover;
	background-position: center center;
}