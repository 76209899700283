.contact-form {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.contact-form .input {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 5px;
}

.contact-form .input-half {
	width: calc(50% - 10px);
}

.contact-form .input-full {
	width: 100%;
}

.contact-form .input>input,
.contact-form .input>button,
.contact-form .input>textarea {
	font-family: freight-sans-pro;
	padding: 10px 15px;
	width: 100%;
}

.contact-form .input.input-right>input,
.contact-form .input.input-right>button {
	width: auto;
	margin-left: auto;
}

.contact-form .input.input-right>button {
	background: #fa0;
	border: none;
	color: #fff;
	font-size: 1.2em;
	cursor: pointer;
	outline: none;
}

.contact-form .input.input-right>button:hover {
	text-decoration: underline;
}

.confirmation-message {
	padding: 15px;
	/* background: rgba(0, 255, 0, 0.25); */
	border: 1px solid #fa0;
}

.home-header .confirmation-message {
	font-size: 0.4em;
}