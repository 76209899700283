.article-header-link:hover {
    text-decoration: none;
}

.article-header {
    color: #fff;
    padding: 200px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100vw;
	margin-left: calc(-50vw + 50%);
    text-align: center;
}

.article-header h1 {
    background: rgba(0, 0, 0, 0.4);
    margin: 0;
    width: 100%;
    padding: 0 20px;
}

.article-excerpt {
    text-align: left;
}

blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
    color: #ccc;
    content: "\f10d";
    font-size: 2.5em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
	font-family: 'Font Awesome 6 Pro';
}

blockquote p {
    display: inline;
}

figure img {
    vertical-align: middle;
}

figcaption {
    font-size: 1rem;
}

.reactive-proactive-post {
    background: url("./reactive-proactive.png");
    background-position: center center;
    background-size: cover;
}

.it-apps-difference-post {
    background: url("./it-apps-blog-header.png");
    background-position: center center;
    background-size: cover;
}

.offshore-problems-post {
    background: url("./offshore-problems-blog-header.png");
    background-position: center center;
    background-size: cover;
}

.thought-barrier-post {
    background: url("./thought_barrier_header.png");
    background-position: center center;
    background-size: cover;
}

.why-support-post {
    background: url("./why-support-header.png");
    background-position: center center;
    background-size: cover;
}

.it-vs-applications-support-post {
    background: url("./it-vs-applications-support-header.png");
    background-position: center center;
    background-size: cover;
}

.mobile-development-post {
    background: url("./mobile-development-header.png");
    background-position: center center;
    background-size: cover;
}

.should-build-mobile-app-post {
    background: url("./should-build-mobile-app-header.png");
    background-position: center center;
    background-size: cover;
}

.benefits-pwa-post {
    background: url("./benefits-pwa-header.png");
    background-position: center center;
    background-size: cover;
}

.benefits-discovery-post {
    background: url("./discovery-benefits-header.png");
    background-position: center center;
    background-size: cover;
}

.user-experience-costs-post {
    background: url("./user-experience-costs-header.png");
    background-position: center center;
    background-size: cover;
}

.site-conversion-rate-post {
    background: url("./site-conversion-rate-header.png");
    background-position: center center;
    background-size: cover;
}

.step-containers article {
    margin: 0 0 20px;
}