.site-navigation {
	display: flex;
	list-style: none;
	font-size: 1.8rem;
	margin: 0;
	padding: 0;
	gap: 30px;
}

.site-navigation > li {
	position: relative;
	display: flex;
	justify-content: center;
}

.site-navigation > li:first-child {
	margin-left: 0;
}

.site-navigation > li a,
.site-navigation > li a:hover {
	color: #09415b;
	z-index: 1;
	position: relative;
	text-transform: uppercase;
}

.blue-circle {
	transition: all 0.25s;
	font-size: 0.5em;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: absolute;
	top: 0;
	left: -13px;
	height: 100%;
}

.site-navigation > li:hover {
	cursor: pointer;
}

.site-navigation > li:hover > a {
	text-decoration: none;
}

.site-navigation li i.fas.fa-circle {
	transition: all 0.25s;
	font-size: 100%;
	color: #ffaa00;
}

.site-navigation li:hover .blue-circle,
.site-navigation li .active .blue-circle {
	left: calc(50% - 2.5em);
}

.site-navigation li:hover i.fas.fa-circle,
.site-navigation li .active i.fas.fa-circle {
	font-size: 5em;
	color: #1281b3;
	opacity: 0.25;
}

.sub-menu {
	display: none;
	padding: 0;
	list-style: none;
	position: absolute;
	top: 25px;
	background: white;
	border: 1px solid #09415b;
	padding: 15px;
	text-align: center;
	z-index: 999;
	min-width: 150px;
}

.site-navigation li:hover .sub-menu {
	display: block;
}

.site-navigation li .sub-menu > li > a {
	padding: 5px 0;
	display: block;
}

.site-navigation li .sub-menu > li > a.active {
	text-decoration: underline;
}

.toggle-button {
	display: none;
	width: 30px;
	height: 30px;
}

.toggle-button > svg {
	fill: black;
}

.toggle-button:hover {
	cursor: pointer;
}

@media screen and (max-width: 929px) {
	.site-footer .site-navigation {
		flex-direction: column;
	}
}

@media screen and (max-width: 899px) {
	.site-header {
		flex-wrap: wrap;
	}

	.site-header .toggle-button {
		display: block;
		padding: 10px 10px 0 0;
	}

	.site-header .site-navigation {
		display: none;
	}

	.site-header .site-navigation.active {
		display: flex;
		flex-direction: column;
		order: 3;
		width: 100%;
		font-size: 2.1rem;
		background: #ededed;
		padding: 15px;
		border-bottom: 1px solid #09415b;
	}

	.site-header .site-navigation.active > li {
		padding: 10px 0;
		text-align: center;
		margin: 0;
		flex-direction: column;
	}

	.site-header .site-navigation.active > li:first-child {
		margin-left: 0;
	}

	.site-header .site-navigation>li:hover>a {
		text-decoration: underline;
	}

	.site-header .site-navigation.active li .sub-menu {
		display: block;
		width: auto;
		position: relative;
		right: 0;
		top: 0;
		background: #ededed;
		border-color: #ccc;
	}

	.site-header .blue-circle {
		display: none;
	}
}


.site-footer .site-navigation > li a {
	color: #fff;
}