.logo-container {
	width: 25rem;
}

@media screen and (max-width: 799px) {
	.logo-container {
		padding: 10px 0 0 10px;
	}
}

.cls-1 {
	fill: #1281b3;
}

.cls-2 {
	fill: #09415b;
}

.cls-3 {
	fill: #fa0;
}