.home-header {
	background-color: #1281b3;
	width: 100%;
	height: calc(80vh - 82px);

	position: fixed;
	top: 82px;
	text-align: center;
}

#banner {
	position: fixed;
	right: 0;
	top: 82px;
	bottom: 0;
	left: 0;
	min-width: 100%;
	min-height: calc(80vh - 82px);
	opacity: 0.25;
}

.home-header>.content {
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	width: 90%;
	margin: auto;
	height: calc(80vh - 82px);
	justify-content: center;
	align-items: center;
	color: #fff;
}

.home-header {
	font-size: calc(2.2vw + 2.8rem);
}

.home-header h1 {
	font-size: 1.5em;
	padding: 0 10px;
	color: #fff;
	line-height: 0.9;
	margin: 0;
}

@media (min-width: 1200px) {
	.home-header h1 {
		font-size: 4.2vw;
	}
}

.home-header h1>strong {
	font-size: 1.3em;
}

.home-header h1+span {
	margin-top: 20px;
	font-size: 0.5em;
}

.home-header .content>h2 {
	font-size: 0.75em;
	margin: 20px 0 0;
}

.home-header .content label {
	font-size: 0.3em;
}

.home-header .content input,
.home-header .content textarea {
	background-color: rgba(255, 255, 255, 0.5);
	font-size: 1.6rem;
	border: 1px solid #000;
	color: #fff;
}

.home-header .content .contact-form .input.input-right>button {
	background: none;
	border: 1px solid #fff;
	font-size: 0.3em;
}

.home-content {
	margin-top: calc(80vh - 82px);
	background: #fff;
	overflow: hidden;
	border-top: 1px solid #09415b;
	z-index: 1;
	width: 100%;
	box-shadow: 0 0 10px #09415b;
	text-align: center;
}

.cta-block {
	padding: 6rem 2rem 0;
	width: 100%;
	z-index: 1;
	position: relative;
}

.cta-block:last-child {
	padding-bottom: 5rem;
}

.cta-block>h2 {
	margin: 0;
	font-size: 6rem;
	z-index: 0;
}

.cta-block .step-fullwidth {
	border: none;
	padding: 0;
}

.cta-block .cta-items {
	padding: 20px 0;
	z-index: 1;
	position: relative;
	text-align: center;
}

.cta-items>ul {
	list-style: none;
	display: flex;
	justify-content: space-evenly;
	max-width: 1336px;
	margin: 20px auto;
	padding: 0 10px;
	width: 100%;
	flex-wrap: wrap;
	gap: 20px;
}

.cta-items>ul>li {
    padding: 20px 20px 40px;
    flex: 1 1 calc(50% - 20px);
    background: #DDDDDD55;
    border-radius: 20px;
	box-shadow: 0 0 8px #09415b;
}

.cta-block .cta-items li h3 {
    font-weight: 600;
    margin: 0 0 10px;
    font-size: 1.5em;
}

.cta-block .cta-items h3,
.cta-block .cta-items p {
	background: none;
	margin: 0;
	font-size: 2.1rem;
}

.cta-block.light-block:before,
.cta-block.light-block:after,
.cta-block.dark-block:after {
	content: "";
	border-radius: 50%;
	position: absolute;
	z-index: -1;
	opacity: 0.35;
}

.cta-block.light-block:before {
	background: #1281b3;
	width: 1200px;
	height: 1200px;
	top: -890px;
	left: -510px;
}

.cta-block.light-block:after {
	background: #fa0;
	width: 300px;
	height: 300px;
	right: -80px;
	bottom: -70px;
}

.cta-block.dark-block:after {
	background: #09415b;
	width: 800px;
	height: 800px;
	right: 100px;
	bottom: -400px;
}

.services-cta .cta-items>ul>li {
	width: 25%;
}

.cta-items blockquote {
	background: none;
	border: none;
	width: 90%;
	margin: 0 auto 1.5em;
	font-size: 1em;
}

@media screen and (max-width: 849px) {
	.cta-items blockquote {
		width: 90%;
	}
}

.cta-items blockquote:before {
	content: '';
}

.cta-button {
	position: relative;
	margin-top: calc(-0.5em - 8px);
	z-index: 2;
	display: flex;
	justify-content: center;
}

.cta-action .cta-button {
	margin-top: 1em;
}

.cta-button>a {
	background: #fa0;
	padding: 5px 30px;
	border: 3px solid #09415b;
	border-radius: 20px;
	color: #09415b;
	font-weight: 600;
}

@media screen and (max-width: 849px) {
	.dark-block .cta-items>ul>li {
		width: auto;
	}
}

@media screen and (max-width: 499px) {
	.cta-items>ul {
		flex-direction: column;
	}

	.cta-items>ul>li,
	.dark-block .cta-items>ul>li {
		width: 100%;
	}
}

.react-multi-carousel-list {
    margin-top: 3em;
}